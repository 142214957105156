import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import tw, { css, styled } from "twin.macro";
import { useInView } from "react-intersection-observer";
import { Layout, Grid, Go, Content, Logo } from "~components";
import { useApp } from "~hooks";
import { breakpoint } from "~utils/css";

/** --------------------------------------------------------------------------
 * @css
 */
const Container = tw.section`relative w-full max-w-[1440px] h-full mx-auto py-6 md-t:py-12 overflow-hidden`;
const Contact = styled.p(({ inView, loading }) => [
  tw`relative w-full col-span-full md-t:col-span-7 mt-[27.8vw] md-t:mt-[12.5vw] font-main italic text-mobile md-t:text-desktop text-purple whitespace-pre-line opacity-0 translate-y-[100%] transition-[opacity,transform,colors]`,
  inView && tw`opacity-100 translate-y-[0%]`,
  loading && tw`pointer-events-none`
]);

const splitStringIntoSentences = (string) => string.split(/\r?\n/);
const removeEndingSpaceFromString = (string) => {
  if (string.endsWith(` `)) {
    return string.slice(0, -1);
  }
  return string;
};
const removeLineSeparatorFromString = (string) =>
  string.replace(/[\r\u2028]/gm, ``);
const breakSentenceIntoWords = (sentence) => sentence.split(` `);
const createSentenceObject = (sentence, index) => {
  let sentenceObject;

  if (sentence[0] === ``) {
    sentenceObject = null;
  } else {
    sentenceObject = {
      id: index,
      ref: null,
      seen: false,
      animate: false,
      finished: false,
      words: sentence.map((word, wordIndex) => ({
        id: wordIndex,
        ref: null,
        animate: false,
        finished: false,
        text: word
      }))
    };
  }

  return sentenceObject;
};
const parseBody = (body) => {
  const newBody = splitStringIntoSentences(body)
    .map((sentence) => removeEndingSpaceFromString(sentence))
    .map((sentence) => removeLineSeparatorFromString(sentence))
    .map((sentence) => breakSentenceIntoWords(sentence))
    .map((sentence, index) => createSentenceObject(sentence, index));

  return newBody;
};

/** --------------------------------------------------------------------------
 * @component TODO: doc
 */
const Index = ({ data: { sanityHome } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { logo, body, services, contact } = sanityHome;
  const { imageLoaded, setImageLoaded, loading, setLoading } = useApp();

  const [contactRef, inView] = useInView({ threshold: 0 });

  const [textSections, setTextSections] = useState([
    {
      id: 0,
      body: parseBody(body),
      animate: true,
      finished: false
    },
    {
      id: 1,
      body: parseBody(services),
      animate: false,
      finished: false
    }
  ]);

  // ---------------------------------------------------------------------------
  // lifecycle

  // wait for image to load
  useEffect(() => {
    if (!imageLoaded) return () => {};

    const timeout = setTimeout(() => {
      setLoading(false);
    }, [5500]);

    return () => {
      clearTimeout(timeout);
    };
  }, [imageLoaded]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Layout
      css={[
        loading
          ? tw`h-screen overflow-hidden select-none`
          : tw`h-auto overflow-auto select-auto`
      ]}
    >
      <Container>
        <Logo logo={logo} loading={loading} imageLoaded={setImageLoaded} />

        <Grid
          css={[
            css`
              transition: opacity 1s ease-in-out;
            `,
            loading ? tw`opacity-0` : tw`opacity-100`
          ]}
        >
          <Content
            text={textSections[0]}
            setTextSections={setTextSections}
            loading={loading}
            css={[
              tw`col-span-full md-t:col-span-8`,
              css`
                margin-top: max(
                  calc(568px - (3rem + (33px * 3))),
                  calc(100vh - (3rem + (33px * 3)))
                );

                ${breakpoint(`md-t`)} {
                  margin-top: max(
                    calc(800px - (6rem + (68.2px * 3))),
                    calc(100vh - (6rem + (68.2px * 3)))
                  );
                }
              `
            ]}
          />

          <Content
            text={textSections[1]}
            setTextSections={setTextSections}
            loading={loading}
            css={[
              tw`col-span-full md-t:col-span-7 mt-[27.8vw] md-t:mt-[12.5vw]`
            ]}
          />

          <Contact ref={contactRef} inView={inView} loading={loading}>
            <Go
              to={contact?.url}
              css={[
                tw`pb-4 border-b hover:border-transparent transition-[colors]`
              ]}
            >
              {contact?.label}
            </Go>
          </Contact>
        </Grid>
      </Container>
    </Layout>
  );
};

export default Index;

export const query = graphql`
  query IndexPage {
    sanityHome {
      logo {
        asset {
          gatsbyImageData(
            width: 720
            formats: [WEBP, PNG, JPG, AVIF]
            placeholder: BLURRED
          )
        }
        altText
      }
      body
      services
      contact {
        label
        url
        newWindow
      }
    }
  }
`;
